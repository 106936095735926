import React, {useState} from 'react';
import {Nav} from "../../UI/Nav";
import {Burger} from "../../UI/Burger";
import { IHeader } from './Header.types';
import defaultLogo from '../../assets/images/svg/logo.svg'
import './header.sass';

export const Header: React.FC<IHeader> = React.memo(({
  activeLink,
  sticky = true,
  logo = defaultLogo,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }

  return (
    <header
      className={`header ${sticky ? 'sticky' : ''}`}
      style={{background: activeLink ? '#FFF' : 'linear-gradient(#481f73, #5f4d72)'}}
    >
      <div className="container">
        <img
          src={logo}
          alt="logo"
          className="logo"
          style={{
            // filter: !activeLink ? 'hue-rotate(180deg) brightness(0) invert(1)' : undefined
          }}
          width="200"
          height="82"
          loading="lazy"
        />
        {!!activeLink && (
          <>
            <Nav isOpen={isOpen} openMenuBurger={openMenu} activeLink={activeLink}/>
            <Burger isOpen={isOpen} openMenuBurger={openMenu}/>
          </>
        )}
      </div>
    </header>
  );
})
