export const NAV_LINK_1 = 'hero'; // Как получить кредит
export const NAV_LINK_2 = 'how-works'; // Как это работает
export const NAV_LINK_3 = 'faq'; // Часто задаваемые вопросы
export const NAV_LINK_4 = 'contacts'; // Контакты
export const RANGE_LINK = 'range'; // Блок hero_range
export const FORM_LINK = 'formular'; // Ссылка на страницу формы
export const PENDING = 'pending'; // Ссылка на страницу ожидания
export const OFFERS = 'offers'; // Ссылка на страницу c ghtlkj;tybzvb
export const REDIRECT_URL = 'https://uverpro.cz/'; // ссылка на витрину
export const EXTERNAL = 'external'; // ссылка на витрину
export const TEST_IFRAME = 'test.iframe'; // ссылка на витрину
export const TEST_OFFERS = 'test.market'; // ссылка на витрину

export const DEFAULT_TLD = 'cz';
export const DEFAULT_HOST = 'offers.finero.cz';

export const TLD = {
	cz: 'cz',
	mx: 'mx',
	kz: 'kz',
	kz_kz: 'kz_kz'
} as const;

export type TLDTypes = keyof typeof TLD;
