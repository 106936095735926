import { useLocation, useNavigate } from 'react-router-dom';
import { EXTERNAL, FORM_LINK, OFFERS, TEST_IFRAME, TEST_OFFERS } from '../../constants/constantApi';

export const Layout = ({children}: {children: any}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const host = window.location.host;
	const targetDomain = host.split('.')[0];
	const isOffers = targetDomain.includes(OFFERS) || host.includes(TEST_OFFERS);
	const isForm = location.pathname === FORM_LINK;
	const isIframe = host.includes(TEST_IFRAME) || host.includes(EXTERNAL);

	if (isOffers) {
		navigate('/offers');

		return null;
	}

	if (isIframe && isForm) {
		navigate(FORM_LINK, { state: { isIframe: true } });

		return null;
	}

	return children;
}