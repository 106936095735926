import React, { useEffect, useId } from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import { useAppDispatch, useAppSelector } from './store';
import {FirstPage} from "./components/FirstPage";
import {Notfound} from "./components/Notfound";
import {FORM_LINK, OFFERS, PENDING} from "./constants/constantApi";
import {Congratulation} from "./components/Congratulation";
import {CustomFormContainer} from "./components/CustomForm/CustomFormContainer";
import { getGeneralConfig } from './store/features/configsSlice/configsSlice';
import { getReviewsData } from './store/features/reviewSlice/reviewSlice';
import { getFormsConfig } from './store/features/formSlice/formSlice';
import { OfferWall } from './components/OfferWall/OfferWall';
import { setClickIDHandler } from './store/features/userSlice/utils';
import { sendSpentTime } from './api/methods/sendUserAction/handlers';
import { useTimer } from './hooks/useTimer';
import { TIME_SPENT_PERIOD } from './constants';
import { Layout } from './components/Layuot/Layout';
// import { sendQueryParams } from './api/methods/sendUserAction/handlers';

export function App() {
  const clickId = useAppSelector(state => state.userData.clickID);
  const dispatch = useAppDispatch();

  const { totalTime } = useTimer();

  useEffect(() => {
    // Setting user's clickID
    setClickIDHandler(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (clickId && totalTime > 0 && totalTime % TIME_SPENT_PERIOD === 0) {
      sendSpentTime(clickId, totalTime);
    }
  }, [totalTime, clickId]);

  const iframeRedirect = (event: any) => {
    if (event.data.type === 'REDIRECT') {
      window.location.href = event.data.url;
    }
  }

  useEffect(() => {
    if (clickId) {
      // Getting configs
      dispatch(getGeneralConfig());
      dispatch(getReviewsData());
      dispatch(getFormsConfig());

      // Sending url's query params
      // sendQueryParams(clickID);
    }
  }, [dispatch, clickId]);

  useEffect(() => {
    window.addEventListener('message', iframeRedirect);

    return () => {
      window.removeEventListener('message', iframeRedirect);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout><FirstPage /></Layout>} />
        <Route path={FORM_LINK} element={<Layout><CustomFormContainer /></Layout>} />
        <Route path={PENDING} element={<Congratulation />} />
        <Route path={OFFERS} element={<OfferWall />} />
        <Route path="/leadsor.frontend" element={<Navigate to="/" replace />} />

        <Route path='*' element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
