import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { apiService } from '../../api/AxiosService';
import { useTimer } from '../../hooks/useTimer';
import { REDIRECT_URL } from '../../constants/constantApi';
import './congratulation.sass';

export function Congratulation() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    urlData: { status_url },
    step,
  } = useAppSelector(state => state.mainData);

  const config = useAppSelector(state => state.configs.general.data?.congratulation);

  const {
    hours, minutes, seconds,
  } = useTimer();

  useEffect(() => {
    if (minutes > 0) {
      apiService.get(status_url)
      .then(res => {
        if(res.data.status === 'OK') {
          if (location?.state?.isIframe) {
            window.parent.postMessage({ type: 'REDIRECT', url: res.data.step.url }, '*');
          } else {
            window.location.href = res.data.step.url
          }
        }
      })
      .catch(err => {
        window.location.href = REDIRECT_URL;
      })
    }
  }, [minutes, status_url]);

  useEffect(() => {
    if (step !== 3) {
      navigate('/');
    }
  }, [step, navigate]);

  if (!status_url) return null;

  return (
    <div className='congratulation'>
      <div className="container">
        <h2 className="congratulation__title">{config?.title ?? ''}</h2>
        <p className="congratulation__description">{config?.description ?? ''}</p>

        <div className="congratulation__inner">
          <div className="clock">
            <div className="clock__face">
              <div className="clock__scoreboard">
                <div className="clock__text">{hours.toString().padStart(2, '0')}</div>
                <span className="clock__text">:</span>
                <div className="clock__text">{minutes.toString().padStart(2, '0')}</div>
                <span className="clock__text">:</span>
                <div className="clock__text">{seconds.toString().padStart(2, '0')}</div>
              </div>
              <p className="clock__title">{config?.clockTitle ?? ''}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
