import { TLD } from "../../constants/constantApi";
import { getDomain } from "../../utils/getTld";

const host = getDomain(window.location.href);

export const HOME_ROUTE = '/';

// mx.api.leadsor.com

export const URL_REGISTER_LEADS = 'https://lead-api.com/api/leads/register';
export const URL_USER_EVENTS = `https://${host || TLD.cz}.api.leadsor.com/api/v1/click-eventurer/events`;

export const PAYDAY_CZ = 'paydaycz';
export const PAYDAY_ZN = 'znvsbgxvyw5jejik';

export const URL_REGISTER_KZ_LEADS = 'https://app.vipn.eu/api/v1/conversion';
