import { GetThunkAPI } from "@reduxjs/toolkit";
import { Inputs, IObject } from "../../../utils/types";
import { LocalStorageService } from "../../../utils/localStorageService";
import { RootState } from "../../rootReducer";
import { getKZRequestParams, getRequestParams } from "./helpers";
import { apiService } from "../../../api/AxiosService";
import { PAYDAY_CZ, PAYDAY_ZN, URL_REGISTER_KZ_LEADS, URL_REGISTER_LEADS } from "../../../assets/helpers/constantAPI";
import { getStepsLength } from "../../../utils/configHelpers";
import { getInputsForm } from "../../../constants/form";
import { setUrlPending, updateOldStep, updateStep } from "../mainData/mainDataSlice";
import { sendToNextStepFormHandle } from "../../../api/methods/sendUserAction/handlers";
import { getDomain } from "../../../utils/getTld";

const sendCZFormData = async (
	data: Inputs,
	thunkApi: GetThunkAPI<any>
) => {
	LocalStorageService.set<string>('form-data', JSON.stringify(data));

	const currentState = thunkApi.getState() as RootState;
	const step = currentState.mainData.step;
	const clickId = currentState.userData.clickID;

	const urlParams = getRequestParams(currentState, data);

	let errors: IObject<string[]> = {};

	try {
		const result = await apiService.post(
			`${URL_REGISTER_LEADS}/${currentState.mainData.money > 9e3 ? PAYDAY_ZN : PAYDAY_CZ}/`, urlParams
		);

		const status = result.data.status;
		const dataErrors = result.data.errors;
		const stepsLength = getStepsLength(currentState.formData.configForms.data?.stepsLength);

		if ( status === 'FAIL' ) {
			errors = result.data.errors;

			if (dataErrors.hasOwnProperty('bank_account_prefix')) {
				errors['bank_acc_number'] = result.data.errors['bank_account_prefix']
			}

			if (dataErrors.hasOwnProperty('bank_account_number')) {
				errors['bank_acc_number'] = result.data.errors['bank_account_number']
			}

			const inputsForm = getInputsForm(currentState.formData.configForms.data?.title ?? [])
			const formStepList = (inputsForm[currentState.mainData.step - 1] ?? []).map(item => item.inputs);

			const isEmptyStepError = formStepList[0]
				.filter(item => errors[item])
				.map(item => errors[item])
				.length === 0;

			if (isEmptyStepError) {
				if (step < stepsLength) {
					thunkApi.dispatch(updateStep(step + 1));

					if (clickId)
						sendToNextStepFormHandle(clickId, step);
				}
			} else {
				thunkApi.dispatch(updateOldStep(currentState.mainData.step));
			}
		} else {
			thunkApi.dispatch(updateStep(stepsLength + 1));
			thunkApi.dispatch(setUrlPending(result.data.step));

			if (clickId)
				sendToNextStepFormHandle(clickId, step);
		}

		return {
			errors,
			data,
		}

	} catch (e) {
		console.log(e);
		return thunkApi.rejectWithValue(String(e));
	}
}

const sendESFormAction = (
	data: Inputs,
	thunkApi: GetThunkAPI<any>,
) => {
	LocalStorageService.set<string>('form-data', JSON.stringify(data));

	const currentState = thunkApi.getState() as RootState;
	const step = currentState.mainData.step;
	const clickId = currentState.userData.clickID;

	const urlParams = getRequestParams(currentState, data);

	let errors: IObject<string[]> = {};

	try {
		return {
			errors,
			data,
		}
	} catch(e) {
		console.log(e);
		return thunkApi.rejectWithValue(String(e));
	}
}

const sendKZFormAction = async (
	data: Inputs,
	thunkApi: GetThunkAPI<any>,
) => {
	LocalStorageService.set<string>('form-data', JSON.stringify(data));

	const currentState = thunkApi.getState() as RootState;
	const step = currentState.mainData.step;
	const clickId = currentState.userData.clickID;
	const stepsLength = getStepsLength(currentState.formData.configForms.data?.stepsLength);

	const urlParams = await getKZRequestParams(currentState, data);
	
	let errors: IObject<string[]> = {};

	try {
		const result = await apiService.post(URL_REGISTER_KZ_LEADS, { body: urlParams });

		if (result.status === 200) {
			if (step >= stepsLength) {
				thunkApi.dispatch(updateStep(step + 1));
	
				if (clickId)
					sendToNextStepFormHandle(clickId, step);
			}
		}

		return {
			errors,
			data,
		}
	} catch(e) {
		console.log(e);
		return thunkApi.rejectWithValue(String(e));
	}
}

const sendMXFormAction = (
	data: Inputs,
	thunkApi: GetThunkAPI<any>,
) => {
	LocalStorageService.set<string>('form-data', JSON.stringify(data));

	const currentState = thunkApi.getState() as RootState;
	const step = currentState.mainData.step;
	const clickId = currentState.userData.clickID;

	const urlParams = getRequestParams(currentState, data);

	let errors: IObject<string[]> = {};

	try {
		return {
			errors,
			data,
		}
	} catch(e) {
		console.log(e);
		return thunkApi.rejectWithValue(String(e));
	}
}

const sendFormActions = {
	cz: sendCZFormData,
	es: sendESFormAction,
	kz: sendKZFormAction,
	mx: sendMXFormAction
} as const;

type sendFormActionsType = keyof typeof sendFormActions;

export const getSendFormAction = () => {
	const tld = (getDomain(window.location.href)) as sendFormActionsType;

	return sendFormActions[tld];
}
