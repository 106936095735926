import { lazy, Suspense, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActiveLink } from '../../store/features/mainData/mainDataSlice';
import { CardList } from '../CardList/CardList';
import { getOffersData, setIsViewed } from '../../store/features/offersSlice/offersSlice';
import { IOffersData } from '../../configs/offers/offers.types';
import { StateContainer } from '../../UI/StateContainer/StateContainer';
import { useSendScrollPosition } from '../../api/methods/sendUserAction/hooks';
import { sendOffersOpened } from '../../api/methods/sendUserAction/handlers';
import { Header } from '../Header';
import './OfferWall.sass';

const Footer = lazy(() => import('../Footer/Footer'));
const Review = lazy(() => import('../Review/Review'));

const offersRender = (
	{ data, settings, tags }: IOffersData,
	onClickOffer: (id: number) => void,
) => {

	const best = data.slice(0, 4);
	const cards = data.slice(4);
	
	return (
		<>
			<div className="offers-title">
				<h2>
					{settings.title}
				</h2>
				<h5>
					{settings.subtitle}
				</h5>
			</div>
			<div className="offers-cards">
				<div className="right">
					{best.length > 0 && (
						<CardList
							data={best}
							category={'best'}
							tags={tags}
							settings={settings}
							onClickOffer={onClickOffer}
						/>
					)}
					{cards.length > 0 && (
						<CardList
							data={cards}
							tags={tags}
							settings={settings}
							onClickOffer={onClickOffer}
						/>
					)}
				</div>
			</div>
			<Suspense fallback={<p>Loading...</p>}>
				<Review />
			</Suspense>
		</>
	)
}

export const OfferWall = () => {
	const dispatch = useAppDispatch();

	const { data, isLoading, error, isUploaded } = useAppSelector(state => state.offers);

	const clickId = useAppSelector(state => state.userData.clickID);

	useSendScrollPosition(clickId, 'scroll_position_offerwall');

	useEffect(() => {
		dispatch(getOffersData({ isFromStorage: true }));
	}, [dispatch]);

	useEffect(() => {
		let timeout = setTimeout(() => {
			if (!isUploaded) {
				dispatch(getOffersData({ isFromStorage: false, withoutLoader: true }));
			}
		}, 10000);

		return () => {
			clearTimeout(timeout);
		}
	}, [dispatch, isUploaded]);

	useEffect(() => {
		if (clickId) {
			sendOffersOpened(clickId);
		}
	}, [clickId]);

	const onClickOffer = (id: number) => {
		dispatch(setIsViewed({id, cookieExpiresDays: data?.settings.cookieExpiresDays}));
	};

	return (
		<div className="offers-container">
			<Header activeLink={''} sticky={false} logo={data?.settings.logo} />
			<div className="container offers-container__content">
				{(isLoading || (!data && !isLoading && !error)) && (
					<StateContainer text="Loading..." />
				)}
				{!isLoading && (error || (!error && !data)) && (
					<StateContainer text="Something went wrong. Please, try again" />
				)}
				{!isLoading && !!data && offersRender(data, onClickOffer)}
			</div>
			<footer className="offers-container__footer">
				<Suspense fallback={<p>Loading...</p>}>
					<Footer setActive={setActiveLink}/>
				</Suspense>
			</footer>
		</div>
	)
}