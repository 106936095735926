import lendswap_mx from './img/1_lendswap_mx.svg';
import loaney_mx from './img/2_loaney_mx.png';
import kimbi_mx from './img/3_kimbi_mx.png';
import dineria_mx from './img/4_dineria_mx.png';
import credito365_mx from './img/5_credito365_mx.png';
import clicredito_mx from './img/6_clicredito_mx.svg';
import alvos_mx from './img/7_alvos_mx.png';
import vexi_mx from './img/8_vexi_mx.png';
import moneycat_mx from './img/9_moneycat_mx.svg';
import kueski_mx from './img/10_kueski_mx.svg';
import vivus from './img/11_vivus.svg';
import coppel from './img/12_coppel.svg';
import logo from './img/logo.png';

const data = {
	"data": [
		{
      "id": 1,
      "title": "LendSwap",
      "money": "5 000 $",
      "img": lendswap_mx,
      "link": "https://track.finero.mx/click/11",
      "term": "",
      "tagId": [11]
    },
    {
      "id": 2,
      "title": "Loaney",
      "money": "5 000 $",
      "img": loaney_mx,
      "link": "https://track.finero.mx/click/12",
      "term": "",
      "tagId": [9]
    },
    {
      "id": 3,
      "title": "Kimbi",
      "money": "5 000 $",
      "img": kimbi_mx,
      "link": "https://track.finero.mx/click/3",
      "term": "",
      "tagId": [3]
    },
    {
      "id": 4,
      "title": "Dineria",
      "money": "35 000 $",
      "img": dineria_mx,
      "link": "https://track.finero.mx/click/2",
      "term": "",
      "tagId": [2]
    },
		{
      "id": 5,
      "title": "Credito365",
      "money": "20 000 $",
      "img": credito365_mx,
      "link": "https://track.finero.mx/click/1",
      "term": "",
      "tagId": [1]
    },
    {
      "id": 6,
      "title": "Clicredito",
      "money": "8 000 $",
      "img": clicredito_mx,
      "link": "https://track.finero.mx/click/16",
      "term": "",
      "tagId": [16]
    },
    {
      "id": 7,
      "title": "Alvos",
      "money": "8 000 $",
      "img": alvos_mx,
      "link": "https://track.finero.mx/click/5",
      "term": "",
      "tagId": [5]
    },
    {
      "id": 8,
      "title": "Vexi",
      "money": "100 000 $",
      "img": vexi_mx,
      "link": "https://track.finero.mx/click/10",
      "term": "",
      "tagId": [13, 10]
    },
    {
      "id": 9,
      "title": "Moneycat",
      "money": "4 000 $",
      "img": moneycat_mx,
      "link": "https://track.finero.mx/click/14",
      "term": "",
      "tagId": [14]
    },
    {
      "id": 10,
      "title": "Kueski",
      "money": "20 000 $",
      "img": kueski_mx,
      "link": "https://track.finero.mx/click/17",
      "term": "",
      "tagId": [17]
    },
    {
      "id": 11,
      "title": "Vivus",
      "money": "10 000 $",
      "img": vivus,
      "link": "https://track.finero.mx/click/7",
      "term": "",
      "tagId": [7]
    },
    {
      "id": 12,
      "title": "Coppel",
      "money": "50 000 $",
      "img": coppel,
      "link": "https://track.finero.mx/click/15",
      "term": "",
      "tagId": [25]
    }
	],
	"tags": [
		{
			"id": 1,
			"name": "Obtén dinero en 10 minutos"
		},
		{
			"id": 2,
			"name": "Sin tarifas ocultas"
		},
		{
			"id": 3,
			"name": "Primer préstamo gratis"
		},
		{
			"id": 4,
			"name": "Tu dinero en menos de 1 hora"
		},
		{
			"id": 5,
			"name": "Sin papeleos"
		},
		{
			"id": 6,
			"name": "Primer préstamo gratis"
		},
		{
			"id": 7,
			"name": "Sin tarifas ocultas"
		},
		{
			"id": 8,
			"name": "Cuenta de ahorro"
		},
		{
			"id": 9,
			"name": "100% online"
		},
		{
			"id": 10,
			"name": "Sin historial crediticio"
		},
		{
			"id": 11,
			"name": "Alta tasa de aprobación"
		},
		{
			"id": 12,
			"name": "Préstamo por tu auto"
		},
		{
			"id": 13,
			"name": "Tarjeta de Crédito"
		},
		{
			"id": 14,
			"name": "Alto índice de aprobación"
		},
		{
			"id": 15,
			"name": "Rápido y seguro"
		},
		{
			"id": 16,
			"name": "Dinero en 5 minutos"
		},
		{
			"id": 17,
			"name": "¡al 0% interés"
		},
		{
			"id": 18,
			"name": "Tarjeta de Crédito"
		},
		{
			"id": 19,
			"name": "3 meses sin intereses"
		},
		{
			"id": 20,
			"name": "Tarjeta de Crédito"
		},
		{
			"id": 21,
			"name": "Meses sin intereses"
		},
		{
			"id": 22,
			"name": "Tarjeta de Crédito"
		},
		{
			"id": 23,
			"name": "Meses sin intereses"
		},
		{
			"id": 24,
			"name": "Trámite sin costo"
		},
		{
			"id": 25,
			"name": "Crédito Coppel"
		}
	],
	"settings": {
		"rangeMoney": {
			"title": "Importe",
			"currency": "$"
		},
		"linkText": "¡Solicítalo ya!",
		"cookieExpiresDays": 2,
		"title": "Préstamos rápidos",
		"subtitle": "La máxima probabilidad de que te aprueben un microcrédito es solicitándolo en varias compañías.",
		logo: logo
	}	
}

export default data;
